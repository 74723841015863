import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";

const Editor = (props) => {
  const { value, set_value, is_disabled, placeholder } = props;
  const editor_ref = useRef(null);

  const [char_count, set_char_count] = useState(0);
  const [word_count, set_word_count] = useState(0);

  useEffect(() => {
    if (value) {
      const plainText = stripHtmlTags(value);
      set_char_count(plainText.length);
      set_word_count(countWords(plainText));
    }
  }, [value]);

  useEffect(() => {
    // Wait for the JoditEditor to mount
    if (editor_ref.current) {
      const interval = setInterval(() => {
        const iframe = editor_ref.current?.querySelector("iframe");
        if (iframe) {
          clearInterval(interval); // Stop checking once the iframe is found
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          const styleElement = iframeDocument.createElement("style");
          styleElement.textContent = `
            ::-webkit-scrollbar {
              width: 20px;
            }
            ::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px grey;
              border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb {
              background: red;
              border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb:hover {
              background: #b30000;
            }
          `;
          iframeDocument.head.appendChild(styleElement);
        }
      }, 100); // Check every 100ms until the iframe is found
    }
  }, []);

  const config = useMemo(
    () => ({
      readonly: is_disabled || false,
      placeholder: placeholder || "Start typing...",
      disablePlugins: ["add-new-line"],
      removeButtons: ["file"],
      toolbarSticky: true,
      style: {
        "font-size": "16px",
      },
      uploader: {
        insertImageAsBase64URI: true,
      },
      imageDefaultWidth: 500,
    }),
    [is_disabled, placeholder]
  );

  const handle_blur = (content) => {
    // set_value((prev) => (prev ? `${prev},${content}` : content));
    set_value({ html: content || "", json: "" });
  };
  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp?.textContent || tmp?.innerText || "";
  };

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    return words.length === 1 && words[0] === "" ? 0 : words.length;
  };

  return (
    <div className="editor-container">
      <JoditEditor
        ref={editor_ref}
        value={`${value || ""}`}
        config={config}
        onBlur={handle_blur}
      />
    </div>
  );
};

export default Editor;
