import React, { useEffect, useState } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PageTitle from "../../system/page_title/page_title";
import { Box, HStack, VStack } from "native-base";
import { Button, DatePicker, Form, Select, TimePicker } from "antd";
import HistoryTitle from "../admin_fact_check_history/history_title";
import HistorySubtitle from "../admin_fact_check_history/history_subtitle";
import { navigateBack } from "../../../../helpers/navigator";
import { useTranslation } from "react-i18next";
import Content from "../admin_article/admin_article_form/content";
import VForm from "../../system/ui/antd_form";
import ArticleHistoryContent from "./article_history_content";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { retrieveItem } from "../../../../helpers/functions";
import {
  dynamic_request,
  get_all_languages_query,
  get_article_history_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import Category from "../admin_article/admin_article_form/categories";
import PartyTag from "../admin_article/admin_article_form/party_tags";

dayjs.extend(utc);
dayjs.extend(timezone);

const ArticleHistoryDetails = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const role = retrieveItem("role");
  const [content, set_content] = useState({ html: null, json: null });
  const data = useDynamicSelector("get_article_history");
  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );

  useEffect(() => {
    if (id) {
      get_article_history_details();
      get_all_languages();
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      let formatted_date = null;
      let formatted_time = null;
      if (data?.published_date_time) {
        const moment_object = dayjs(data?.published_date_time).tz(
          "Asia/Kolkata"
        );
        formatted_date = moment_object
          .subtract(5, "hour")
          .subtract(30, "minute")
          .format("YYYY-MM-DD");
        formatted_time = moment_object;
      }
      form.setFieldsValue({
        ...data,
        category_tags: data?.category_tags?.map((x) => {
          return x.id;
        }),
        party_tags: data?.party_tags?.map((x) => {
          return x.id;
        }),
        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ss")
          : null,
        content: data?.content
          ? set_content({ html: data?.content, json: data?.content_json })
          : set_content({ html: null, json: null }),
        thumbnail_img: data?.thumbnail_img
          ? [
              {
                url: data?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
      if (data?.content || data?.content_json) {
        set_content({
          html: data?.content || null,
          json: data?.content_json || null,
        });
      }
    }
  }, [data]);

  const handle_modal_close = () => {
    navigateBack();
  };

  const get_article_history_details = () => {
    let key = [{ key: "get_article_history", loading: true }];
    let query = get_article_history_query;
    let variables = {
      id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <>
      <PageTitle title={"Article History Details"} />
      <VStack padding={"30px"}>
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button onClick={handle_modal_close} danger>
            {t("back")}
          </Button>
        </HStack>
        <Form id="fact_check_form" form={form} layout="vertical">
          <HistoryTitle />
          <HistorySubtitle />
          <HStack space={"10px"}>
            <Box flex={1}>
              <Form.Item name="language_id" label={"Language"}>
                <Select disabled>
                  {system_languages?.map((option, index) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Box>

            {role === "Admin" && (
              <Box flex={2}>
                <HStack space={"10px"}>
                  <Form.Item
                    name="published_date"
                    label={"Published Date and Time"}
                  >
                    <DatePicker disabled format={"YYYY-MM-DD"} />
                  </Form.Item>
                  <Form.Item name="published_time" label={" "}>
                    <TimePicker disabled use24Hours format={"HH:mm:ss"} />
                  </Form.Item>
                </HStack>
              </Box>
            )}
          </HStack>

          <HStack space={"10px"}>
            <Box flex={1}>
              <VForm.TextBox disabled label="Perma link" field="perma_link" />
            </Box>
            <Box flex={1}>
              <Category disabled language_id={data?.language_id} />
            </Box>
            <Box flex={1}>
              <PartyTag disabled language_id={data?.language_id} />
            </Box>
          </HStack>
          <VForm.ImageFile
            label="Thumbnail Image"
            field={"thumbnail_img"}
            disabled
          />
          <Content
            disabled={true}
            content={content}
            set_content={set_content}
          />
        </Form>
      </VStack>
    </>
  );
};

export default ArticleHistoryDetails;
