import { gql } from "@apollo/client";

export const payment_handler = gql`
  mutation create_donation($amount: Int!, $mobile: String, $address: String) {
    create_donation(amount: $amount, mobile: $mobile, address: $address) {
      status
      order_id
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_donations_query = gql`
  query get_all_donations(
    $page_limit: Int
    $page_number: Int
    $search_string: String
    $filter: donation_filter_input
  ) {
    get_all_donations(
      page_limit: $page_limit
      page_number: $page_number
      search_string: $search_string
      filter: $filter
    ) {
      items {
        id
        amount
        payment_method
        receipt
        transaction_id
        address
        mobile
        name
        pan_no
        created_date_time
        system_user {
          id
          username
          user_profile {
            name
            mobile
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;
